import * as React from "react"
import { Link } from "gatsby"
import Image from "../../components/image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import "../../assets/dev.sass"

const Dev = () => (
  <div style={{textAlign:"center", padding:"40px"}} className="dev-container">
    <Seo title="The Dev" />
    <h2>COMING SOON.</h2>
  </div>
)

export default Dev
